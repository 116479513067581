import React from "react"
import { graphql } from "gatsby"
// import { connect } from "react-redux"
// import { createStructuredSelector } from "reselect"

import Layout from "../components/Layout/layout"
import SEO from "../components/Layout/seo"
// import { getExamplesData } from "../redux/Home/action"
// import { selectExamplesData } from "../redux/Home/selectors"
import Banner from "../components/general/Banner";
import AllMenu from "../components/MenuPageComponents/AllMenu";

const MenuPage = ({ data }) => {
  // useEffect(() => {
  //   onGetExamplesData()
  // }, [onGetExamplesData])
  return (
    <Layout data={data}>
      <SEO title="Menu BPK Lopiga Bremaha | Babi Panggang Karo Batam" />
      <Banner />
      <AllMenu />
    </Layout>
  )
};

export const query = graphql`
  query MenuPageQuery {
    site {
      siteMetadata {
        title
        description
        title
        address
        phone
        grabfoodLink
        gofoodLink
        facebook {
          label
          link
        }
        instagram {
          label
          link
        }
      }
    }
  }
`;

// MenuPage.propTypes = {
//   onGetExamplesData: func.isRequired,
// };

// export const mapStateToProps = createStructuredSelector({
//   examplesData: selectExamplesData(),
// });

// export const mapDispatchToProps = {
//   onGetExamplesData: getExamplesData,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(MenuPage)
export default MenuPage;
