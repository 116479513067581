/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { arrayOf, any } from 'prop-types';
import { get, isEmpty } from 'lodash';

import {
  ColWrapper,
  CardWrapper,
  ProductDetail,
  ProductName,
  ProductPrice,
  ProductDescription,
  LinkStyled,
  ProductImage,
} from './styed';
import { priceFormat } from '../../../lib/helpers';

const ProductList = ({ products }) => {
  return (
    <div className="row mt-4">
      {isEmpty(products) && <span style={{ marginLeft: 15 }}>Produk sedang tidak tersedia ...</span>}
      {!isEmpty(products) && products.map(item => {
        const prodName = get(item, 'name', '');
        const prodPrice = priceFormat(get(item, 'price', ''), 'Rp ');
        const detailPath = `/kontak`;
        return (
          <ColWrapper className="col-lg-4 col-md-4 col-xs-3">
            <LinkStyled to={detailPath}>
              <div style={{ marginBottom: 25 }}>
                <CardWrapper>
                  {/* <img
                    alt={prodName}
                    src="https://static.sehatq.com/cdn-cgi/image/f=auto,width=200,height=200,fit=pad,background=white,quality=100/tokoq/products/variants/quzt9a5047ngtmy8lwtfkhofve0y/16135febf8ba6fdffeb50829c0b09f2f649c659f27f200cfdab438675c11b99c"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  /> */}
                  <ProductImage alt={item.alt || prodName} imgID={item.imgID} />
                  <ProductDetail>
                    <ProductName>
                      {prodName}
                    </ProductName>
                    <ProductPrice>
                      <span>{prodPrice}</span>
                    </ProductPrice>
                    {item.description && (
                      <ProductDescription>
                        {item.description}
                      </ProductDescription>
                    )}
                  </ProductDetail>
                </CardWrapper>
              </div>
            </LinkStyled>
          </ColWrapper>
        );
      })}
    </div>
  );
};

ProductList.propTypes = {
  products: arrayOf(any),
};

ProductList.defaultProps = {
  products: [
    {
        "name": "Netflix Premium Ultra HD Sharing",
        "sku": null,
        "quantity": 50,
        "price": 25000,
        "minQtyBuy": 1,
        "currency": "IDR",
        "discount": null,
        "images": [],
        "categories": [],
        "soldCount": 0,
        "viewCount": 0,
        "isActive": true,
        "blockedAt": null,
        "deletedAt": null,
        "createdAt": "2020-07-20T10:30:05.443Z",
        "_id": "5f15722d684d3dbe9fea40ef",
        "updatedAt": "2020-07-20T10:30:05.490Z",
        "__v": 0,
        "details": "http://localhost:8081/api/v1/products/5f15722d684d3dbe9fea40ef"
    },
    {
        "name": "Netflix Premium Ultra HD Private",
        "sku": null,
        "quantity": 50,
        "price": 50000,
        "minQtyBuy": 1,
        "currency": "IDR",
        "discount": null,
        "images": [],
        "categories": [],
        "soldCount": 0,
        "viewCount": 0,
        "isActive": true,
        "blockedAt": null,
        "deletedAt": null,
        "createdAt": "2020-07-20T10:30:15.219Z",
        "_id": "5f157237684d3dbe9fea40f0",
        "updatedAt": "2020-07-20T10:30:15.225Z",
        "__v": 0,
        "details": "http://localhost:8081/api/v1/products/5f157237684d3dbe9fea40f0"
    },
    {
        "name": "Netflix Premium Ultra HD Sharing & Download",
        "sku": null,
        "quantity": 500,
        "price": 35000,
        "minQtyBuy": 1,
        "currency": "IDR",
        "discount": null,
        "images": [],
        "categories": [],
        "soldCount": 0,
        "viewCount": 0,
        "isActive": true,
        "blockedAt": null,
        "deletedAt": null,
        "createdAt": "2020-07-20T10:30:57.040Z",
        "_id": "5f157261684d3dbe9fea40f1",
        "updatedAt": "2020-07-20T10:30:57.042Z",
        "__v": 0,
        "details": "http://localhost:8081/api/v1/products/5f157261684d3dbe9fea40f1"
    },
    {
        "name": "Netflix Premium Ultra HD Sharing & Download",
        "sku": null,
        "quantity": 500,
        "price": 35000,
        "minQtyBuy": 1,
        "currency": "IDR",
        "discount": null,
        "images": [],
        "categories": [],
        "soldCount": 0,
        "viewCount": 0,
        "isActive": true,
        "blockedAt": null,
        "deletedAt": null,
        "createdAt": "2020-07-20T10:30:57.040Z",
        "_id": "5f157261684d3dbe9fea40f1",
        "updatedAt": "2020-07-20T10:30:57.042Z",
        "__v": 0,
        "details": "http://localhost:8081/api/v1/products/5f157261684d3dbe9fea40f1"
    },
    {
        "name": "Netflix Premium Ultra HD Sharing & Download",
        "sku": null,
        "quantity": 500,
        "price": 35000,
        "minQtyBuy": 1,
        "currency": "IDR",
        "discount": null,
        "images": [],
        "categories": [],
        "soldCount": 0,
        "viewCount": 0,
        "isActive": true,
        "blockedAt": null,
        "deletedAt": null,
        "createdAt": "2020-07-20T10:30:57.040Z",
        "_id": "5f157261684d3dbe9fea40f1",
        "updatedAt": "2020-07-20T10:30:57.042Z",
        "__v": 0,
        "details": "http://localhost:8081/api/v1/products/5f157261684d3dbe9fea40f1"
    },
    {
        "name": "Netflix Premium Ultra HD Sharing & Download",
        "sku": null,
        "quantity": 500,
        "price": 35000,
        "minQtyBuy": 1,
        "currency": "IDR",
        "discount": null,
        "images": [],
        "categories": [],
        "soldCount": 0,
        "viewCount": 0,
        "isActive": true,
        "blockedAt": null,
        "deletedAt": null,
        "createdAt": "2020-07-20T10:30:57.040Z",
        "_id": "5f157261684d3dbe9fea40f1",
        "updatedAt": "2020-07-20T10:30:57.042Z",
        "__v": 0,
        "details": "http://localhost:8081/api/v1/products/5f157261684d3dbe9fea40f1"
    }
  ],
};

export default ProductList;
