import React from "react"

import { Section, Title } from "../../lib/utils"
import ProductList from "./ProductList";

const ALL_MENU = [
  {
    id: 1,
    name: 'Babi Panggang Karo Porsi Biasa',
    price: 25000,
    description: 'Babi Panggang Karo Porsi Biasa, sayur daun ubi, cabe andaliman segar dan sup B2 hangat.',
    imgID: 'MenuBPK',
  },
  {
    id: 2,
    name: 'Babi Panggang Karo Porsi JUMBO (Terlaris)',
    price: 45000,
    description: 'Babi Panggang Karo Porsi JUMBO, sayur daun ubi, cabe andaliman segar dan sup B2 hangat.',
    imgID: 'MenuBPK',
  },
  {
    id: 3,
    name: 'Babi Panggang Karo Porsi SUPER JUMBO',
    price: 70000,
    description: 'Babi Panggang Karo Porsi SUPER JUMBO, sayur daun ubi, cabe andaliman segar dan sup B2 hangat.',
    imgID: 'MenuBPK',
  },
  {
    id: 4,
    name: 'Kidu-Kidu Porsi Biasa',
    price: 25000,
    description: 'Kidu-kidu Porsi Biasa, sayur daun ubi, cabe andaliman segar dan sup B2 hangat.',
    imgID: 'MenuKidu',
  },
  {
    id: 5,
    name: 'Kidu-Kidu Porsi JUMBO',
    price: 40000,
    description: 'Kidu-kidu Porsi JUMBO, sayur daun ubi, cabe andaliman segar dan sup B2 hangat.',
    imgID: 'MenuKidu',
  },
  {
    id: 6,
    name: 'Sup Isi B2 Porsi Biasa',
    price: 25000,
    description: 'Sup isi B2 Porsi Biasa, sayur daun ubi, cabe andaliman segar.',
    imgID: 'MenuSoup',
  },
  {
    id: 7,
    name: 'Sup Isi B2 Porsi JUMBO',
    price: 40000,
    description: 'Sup isi B2 Porsi JUMBO, sayur daun ubi, cabe andaliman segar.',
    imgID: 'MenuSoup',
  },
  {
    id: 8,
    name: 'Ikan Mas Arsik',
    price: 25000,
    description: 'Ikan Mas Arsik Potong & Kuah.',
    imgID: 'MenuIkan',
  },
  {
    id: 9,
    name: 'Babi Goreng Porsi Biasa',
    price: 30000,
    description: 'Babi Goreng, Sayur daun ubi, sup B2 hangat dan cabe andaliman segar.',
    imgID: 'MenuB2Goreng',
  },
  {
    id: 10,
    name: 'Babi Goreng Porsi JUMBO',
    price: 55000,
    description: 'Babi Goreng Porsi JUMBO, Sayur daun ubi, sup B2 hangat dan cabe andaliman segar.',
    imgID: 'MenuB2Goreng',
  },
  {
    id: 11,
    name: 'Saksang Porsi Biasa',
    price: 25000,
    description: 'Saksang Porsi Biasa, sayur daun ubi, sup B2 hangat dan cabe andaliman segar.',
    imgID: 'MenuSaksang',
  },
  {
    id: 12,
    name: 'Saksang Porsi JUMBO',
    price: 40000,
    description: 'Saksang Porsi JUMBO, sayur daun ubi, sup B2 hangat dan cabe andaliman segar.',
    imgID: 'MenuSaksang',
  },
];

export default function AllMenu() {
  return (
    <Section>
      <Title title="menu kami" />
      <ProductList products={ALL_MENU} />
      <p>Tunggu menu mantap lainnya dari Kami ya ...</p>
    </Section>
  )
}

