import { Link } from 'gatsby';

import styled from 'styled-components';
import Image from '../../Layout/image';

export const ProductImage = styled(Image)`
  width: 100%;
  height: 300px;
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const ColWrapper = styled.div`
  /* @media (max-width: 768px) {
    padding: 0px 5px;
  } */
`;

export const Container = styled.div`
  margin-bottom: 50px;
`;

export const CardContainer = styled.div`
  display: inline-flex;
  max-width: 20%;
  box-sizing: border-box;
  flex: 0 1 20%;
  padding: 0px 0.8% 1.6%;
`;

export const CardWrapper = styled.div`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px 0px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const ProductDetail = styled.div`
  position: relative;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  padding: 15px;
  overflow: hidden;
`;

// export const ProductImgContainer = styled.div`
//   position: relative;
//   height: 0px;
//   border-top-right-radius: 8px;
//   border-top-left-radius: 8px;
//   padding-bottom: 100%;
//   width: 100%;
//   text-overflow: ellipsis;
//   background: rgb(246, 246, 246);
//   transition: box-shadow 0.25s ease 0s;
//   overflow: hidden;
// `
// export const ProductImgWrapper = styled.div`
//   background-color: transparent;
//   display: inline-block;
//   text-align: center;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   margin: 0px auto;
// `;

export const ProductImg = styled(Image)`
  position: relative;
`;


export const ProductName = styled.span`
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
  font-weight: 600;
  /* height: 2.125rem; */
  /* word-break: break-all; */
  margin-bottom: .25rem;
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  overflow: hidden;
  @media (min-width: 768px) {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    display: -webkit-box;
    margin-bottom: 5px;
    max-height: 38px;
    font-size: 14px;
    line-height: 19px;
    white-space: normal;
  }
`;

export const ProductDescription = styled.p`
  font-size: 14px;
  color: grey;
  margin-top: 10px;
  margin-bottom: 0px;
  height: 4rem;

  // truncated
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

export const ProductPrice = styled.div`
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #F15A21;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
  overflow: hidden;
`;
